html,
body {
  width: 100%;
  height: 100%;
}
body {
  height: 100%;
  font-size: 14px;
  position: relative;
  line-height: 1.65;
  font-family: 'Arimo', sans-serif;
  overflow-x: hidden;
  margin: 0;
}
ul,
ol,
menu,
dir {
  padding: 0;
  margin: 0;
  display: block;
  list-style-type: disc;
}
img {
  width: 100%;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #888;
}
a:hover {
  color: #000;
}
.a_white {
  color: #888;
}
.a_white:hover {
  color: #000;
}
.a_white:active {
  color: #000;
}
.a_white:focus {
  color: #000;
}
.a_black {
  color: #666;
}
.a_black:hover {
  color: #fff;
}
.a_black:active {
  color: #fff;
}
.a_black:focus {
  color: #fff;
}
p {
  font-size: 14px;
}
h1,
h3 {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 768px) {
  h1,
  h3 {
    font-size: 18px;
  }
}
h2 {
  color: #fff;
  text-transform: uppercase;
  padding: 0 7px 7px 7px;
  border-bottom: 1px solid #333;
  font-size: 14px;
  display: inline-block;
  margin: 0 auto;
}
.main_text {
  margin-bottom: 80px;
}
.section_wrappers {
  background-color: #000;
  width: 100%;
  height: 100%;
  background-image: url("9e8b7215a546ccd2c469f74583d1ac51.jpg");
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .section_wrappers {
    position: relative;
    overflow: auto;
  }
}
.section {
  position: absolute;
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .section {
    position: relative;
    width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  .section {
    height: 100%;
  }
}
.section_left {
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .section_left {
    height: 300px;
  }
}
.section_move {
  background-color: #fff;
  right: -1500px;
  z-index: 2;
  transition: all 0.6s ease;
}
@media only screen and (max-width: 768px) {
  .section_move {
    height: 400px;
    right: 0px !important;
  }
}
.section_right {
  right: -1500px;
  top: 0;
  z-index: 0;
  color: #fff;
  transition: all 0.6s ease;
}
@media only screen and (max-width: 768px) {
  .section_right {
    right: 0px;
    z-index: 1;
  }
}
.about_us,
.our_services,
.adress,
.technologies,
.contact,
.adress_local,
.cookies-info {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease;
}
@media only screen and (max-width: 768px) {
  .about_us,
  .our_services,
  .adress,
  .technologies,
  .contact,
  .adress_local,
  .cookies-info {
    height: 650px;
    display: none;
  }
}
.project {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease;
  overflow: auto;
}
@media only screen and (max-width: 768px) {
  .project {
    background: #000;
    display: none;
    overflow: hidden;
    position: relative;
  }
}
.adress {
  color: #000;
}
@media only screen and (max-width: 768px) {
  .about_us {
    z-index: 1;
    opacity: 1;
  }
}
.menu_on {
  opacity: 1;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .menu_on {
    display: inherit;
  }
}
.menu_off {
  opacity: 0;
  z-index: 0;
}
@media only screen and (max-width: 768px) {
  .menu_off {
    display: none;
  }
}
.logo {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -85px 0 0 -150px;
  opacity: 0;
  transition: opacity 2s ease;
}
@media only screen and (max-width: 768px) {
  .logo {
    width: 200px;
    margin: -60px 0 0 -100px;
  }
}
.menu {
  text-align: center;
  width: 300px;
  height: 280px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -140px 0 0 -150px;
}
@media only screen and (max-width: 768px) {
  .menu {
    top: 40%;
  }
}
.menu_telephone {
  display: inline-block;
  border-width: 1px;
  padding: 15px 10px 10px 15px;
  font-size: 18px;
  margin: 30px;
  color: #232323;
}
@media only screen and (max-width: 768px) {
  .menu_telephone {
    padding: 0;
    margin: 20px 0 0 0;
  }
}
.menu_list_wihout_number {
  list-style-type: none;
  text-transform: uppercase;
  line-height: 2.5;
  font-size: 13px;
}
.menu__logo-container {
  position: relative;
}
.menu__logo-container-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.carousel {
  overflow: hidden;
  text-align: center;
  width: 400px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -120px 0 0 -200px;
  color: #fff;
}
@media only screen and (max-width: 480px) {
  .carousel {
    width: 300px;
    margin: -125px 0 0 -150px;
  }
}
.gallery {
  width: 100%;
}
.item {
  display: table;
  line-height: 0;
  width: 33%;
  float: left;
  margin: 0.17%;
}
.technologies_column_left,
.technologies_column_right {
  position: absolute;
  width: 50%;
  text-align: center;
  font-family: 'Oxygen', sans-serif;
  top: 50%;
}
@media only screen and (max-width: 768px) {
  .technologies_column_left,
  .technologies_column_right {
    font-size: 13px;
  }
}
.technologies_column_left {
  margin: -274px 0 0 0;
  padding: 0 0 0 10%;
}
.technologies_column_right {
  right: 0px;
  margin: -232px 0 0 0;
  padding: 0 10% 0 0;
}
.technologies_list_wihout_number {
  list-style-type: none;
  margin: 10px 0 30px 0;
}
.map {
  width: 100%;
  height: 100%;
}
.local {
  position: absolute;
  width: 300px;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
}
.btn_map_close {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #737373;
  width: 58px;
  height: 27px;
  margin: 80px 0 0 0;
}
.icon_close {
  display: inline-block;
  background-position: 0 0;
  height: 25px;
  width: 25px;
  background: url("5fbf9a90f2029e9065041232a0f59098.png") no-repeat;
}
@media only screen and (max-width: 768px) {
  .click_up {
    border: 1px solid #737373;
    height: 58px;
    width: 27px;
    z-index: 30;
    position: absolute;
    right: 15px;
    background: url("3a44ea43e485a421c8e8c082373ac3a5.png") no-repeat;
    top: 50%;
    margin: -29px 0 0 0;
  }
  .click_up:hover {
    border: 1px solid #fff;
  }
}
.contact_text_section {
  position: relative;
  height: 80px;
  width: 100%;
}
.contact_text {
  font-size: 13px;
  color: #c1c1c1;
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  margin: -19px 0 0 -160px;
}
@media only screen and (max-width: 480px) {
  .contact_text {
    margin: -20px 0 0 -120px;
  }
}
.contact_form {
  text-align: center;
  width: 400px;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -200px 0 0 -200px;
  color: #fff;
}
@media only screen and (max-width: 480px) {
  .contact_form {
    width: 300px;
    margin: -200px 0 0 -150px;
  }
}
form input.styled-input {
  background-color: #191919;
  border: 1px solid #737373;
  display: block;
  margin: 10px 0 10px 0;
  font-size: 10px;
  color: #cbcbcb;
  outline: none;
  box-sizing: border-box;
  width: 400px;
  padding: 10px 14px;
  text-transform: uppercase;
}
@media only screen and (max-width: 480px) {
  form input.styled-input {
    width: 300px;
  }
}
form input[type=name],
form input[type=email] {
  background-color: #191919;
  border: 1px solid #737373;
  display: block;
  margin: 10px 0 10px 0;
  font-size: 10px;
  color: #cbcbcb;
  outline: none;
  box-sizing: border-box;
  width: 400px;
  padding: 10px 14px;
  text-transform: uppercase;
}
@media only screen and (max-width: 480px) {
  form input[type=name],
  form input[type=email] {
    width: 300px;
  }
}
form input[type=submit] {
  background-color: rgba(255,255,255,0);
  border-width: 1px;
  border-style: solid;
  border-color: #737373;
  display: block;
  font-size: 10px;
  color: #cbcbcb;
  outline: none;
  width: 40%;
  padding: 10px 14px;
  float: right;
  margin: 9px 2px 3px 5px;
  width: 45%;
  text-transform: uppercase;
}
form input[type=submit]:active {
  border-color: #fff;
}
form input[type=submit]:hover {
  border-color: #fff;
}
.agreement {
  font-size: 9px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 10px;
  color: #cbcbcb;
  text-align: justify;
}
textarea {
  background-color: #191919;
  border: 1px solid #737373;
  display: block;
  margin: 10px 0 10px 0;
  font-size: 10px;
  color: #cbcbcb;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 400px;
  padding: 10px 14px;
  text-transform: uppercase;
  font-family: 'Arimo', sans-serif !important;
}
@media only screen and (max-width: 480px) {
  textarea {
    width: 300px;
  }
}
.send_message {
  text-align: center;
  background-color: #fff;
  color: #000;
  width: 300px;
  height: 200px;
  left: 50%;
  top: 50%;
  margin: -100px 0 0 -150px;
  position: absolute;
}
.send_message_center {
  margin-top: 88px;
}
.error_contact_name,
.error_contact_email,
.error_contact_telephone,
.error_input_text {
  border-color: #f00;
  font-size: 11px;
}
.block_for_slider,
.block_for_slider_services {
  height: 400px;
  width: 400px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -200px 0 0 -200px;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .block_for_slider,
  .block_for_slider_services {
    width: 300px;
    height: 500px;
    margin: -250px 0 0 -150px;
  }
}
.viewport,
.viewport_services {
  font-size: 13px;
  position: relative;
}
.slidewrapper,
.slidewrapper_services {
  position: relative;
  width: calc(100% * 4);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-transition-timing-function: cubic-bezier(0.67, 0.01, 0.23, 1);
  -o-transition-timing-function: cubic-bezier(0.67, 0.01, 0.23, 1);
  transition-timing-function: cubic-bezier(0.67, 0.01, 0.23, 1);
}
.slidewrapper,
.slidewrapper ul,
.slidewrapper li {
  margin: 0;
  padding: 0;
}
.slidewrapper_services,
.slidewrapper_services ul,
.slidewrapper_services li {
  margin: 0;
  padding: 0;
}
.slide,
.slide_services {
  width: calc(100% / 4);
  list-style: none;
  display: inline;
  float: left;
  transition: opacity 2s ease;
}
.slide_text {
  width: 100%;
  color: #c1c1c1;
  font-size: 13px;
  margin: 20px 0 30px 0;
}
.prev_btn,
.prev_btn_services {
  display: inline-block;
  border: 1px solid #737373;
  height: 27px;
  width: 58px;
  cursor: pointer;
}
.next_btn,
.next_btn_services {
  display: inline-block;
  border: 1px solid #737373;
  height: 27px;
  width: 58px;
  cursor: pointer;
}
.prev_btn:hover,
.prev_btn_service:hover {
  cursor: pointer;
}
.prev_btn,
.prev_btn_services {
  left: 20px;
  background: url("5d01e1c77810aa76a0090fff3a55a3bd.png") no-repeat;
  margin-right: 3px;
}
.next_btn,
.next_btn_services {
  right: 20px;
  background: url("6b6e28b8d8406b658d8bfe85ebf26f23.png") no-repeat;
  margin-left: 3px;
}
.nav_btns,
.nav_btns_services {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  top: 70px;
  position: relative;
  z-index: 10;
}
.slide_nav_btn,
.slide_nav_btn_services {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 4px;
}
.slide_nav_btnhover {
  cursor: pointer;
}
.slide_nav_btn_serviceshover {
  cursor: pointer;
}
.slide_nav_btn a {
  display: block;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
}
.slide_nav_btn a:active {
  background-color: #fff;
}
.slide_nav_btn_services a {
  display: block;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
}
.slide_nav_btn_services a:active {
  background-color: #fff;
}
.nav_on {
  background-color: #fff;
}
.nav_off {
  background-color: #3d3d3d;
}
.btn_off {
  opacity: 0.25;
  cursor: default !important;
}
.btn_on {
  opacity: 1;
}
.btn_on:hover {
  border-color: #fff;
}
.valid {
  border: 1px solid #569b44 !important;
}
.invalid {
  border: 1px solid #f00 !important;
}
#send-result {
  display: none;
}
#cookie-banner {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  background: rgba(255,255,255,0.9);
  border: 1px solid #000;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  display: none;
}
.btn {
  background-color: rgba(255,255,255,0);
  border-width: 1px;
  border-style: solid;
  border-color: #737373;
  display: block;
  font-size: 10px;
  outline: none;
  padding: 10px 14px;
  text-transform: uppercase;
  cursor: pointer;
}
.btn:hover {
  color: rgba(115,115,115,0.5);
  border-color: rgba(115,115,115,0.5);
}
.cookie-submit {
  margin-left: 12px;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-right: 12px;
}
.block_for_cookies {
  padding: 16px;
  overflow: auto;
  color: #fff;
  text-align: center;
  height: min-content;
  font-size: 13px;
}
.block_for_cookies ul {
  list-style: none;
}
.cookies-info {
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
#cookie-aggree-btn {
  background-image: url("256eecd06a8ea526a4f1f4a8b2ca91cb.svg");
  background-color: transparent;
  height: 15px;
  width: 15px;
  border: none;
  cursor: pointer;
}
.active_cookies-info {
  text-decoration: underline;
  color: #000 !important;
}
